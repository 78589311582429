import {
  MotorcycleLessonType,
  ProductType,
} from "src/models/AcademyServiceModel/interfaces";
import { GearType } from "../../models/InstructorModel/interfaces";
import { IClientOptions } from "../CoreApiService/interfaces";

export interface IApiService {
  cancelUnpaidOrder(bookingId: string): Promise<void>;
  bindAnonymousUser(anonymousUserId: string): Promise<void>;
}

export interface ICoreApiUserProfile {
  currentLocationId: number | null;
  currentInstructorId: number | null;
  servicesState?: {
    [key in ProfileServicesStateKeys]: {
      date: string | null;
      id: number | null;
    };
  };
  status: string;
  availableRegions: number[];

  availableLocations: number[];
  isAutomaticCar: boolean | null;
}
export interface ICoreApiUserSettings {
  id: number;
  name: string;
  email: string;
  phone: string;
  ssn: string;
  options: IClientOptions;
}

export interface ICoreUserProfile {
  currentLocationId?: number;
  currentInstructorId?: number;
  testLessonDate?: string;
  status: string;
  availableRegions: number[];
  isAutomaticCar?: boolean;
}

enum ProfileServicesStateKeys {
  testLesson = "testLesson",
  educationControl = "educationControl",
  intro = "intro",
  risk1 = "risk1",
  risk2 = "risk2",
  theoryTest = "theoryTest",
  drivingTest = "drivingTest",
}

export interface IApiBooking {
  id: number;
  record_date: string;
  start_date: string;
  end_date: string;
  unit_id: string;
  text: string;
  client: string | null;
  unit: string;
  unit_email: string;
  event: string;
  event_id: string;
  is_confirm: 0 | 1;
  client_id: string | null;
  client_phone: string | null;
  client_email: string | null;
  offset: string;
  comment: string;
  code: string;
  status: null;
  location: string;
  payment_status: "not_paid" | "cancel" | "paid";
  payment_system: "paypal";
  payed_amount: number;
  payed_currency: "SEK";
  promotion_code: null;
  promotion_discount: null;
  isDriving: boolean;
  isUTB: boolean;
  isLocked: boolean;
}

export interface ITimeInterval {
  from: Date;
  to: Date;
}

export interface IApiResponse<TData> {
  errorCode: number;
  data: TData;
}

export interface IApiResponseList<TData> {
  errorCode: number;
  data: {
    total: number;
    result: TData;
  };
}

export enum KlarnaLayout {
  Desktop = "desktop",
  Mobile = "mobile",
}
export interface IKlarnaCheckoutPayload {
  clientId: number;
  eventId: number;
  unitId?: number;
  firstLessonStartDateTime: Date;
  locationId: number;
  promocode?: string;
  giftcards?: string[];
  layout?: KlarnaLayout;
  attendees?: IApiAttendee[];
  klarnaOrderId?: string;
  isClass: boolean;
  productType: ProductType;
  bookingReservationId?: number;
  bookedBundle?: boolean;
  serviceId?: number;
  mcLessonType?: MotorcycleLessonType;
  isNotStudentBookable?: boolean;
}

export interface ICartTotalPrice {
  totalDiscount: number;
  totalPrice: number;
  totalVAT: number;
}
export interface IApiAttendee {
  name: string;
  ssn: string;
}

export interface IApiSaldoAttendee {
  // FIXME !!
  name: string;
  ssn: string;
}
export interface IKlarnaCheckoutResponse {
  readonly success: boolean;
  readonly id: string;
  readonly bookingId: string;
  readonly status: boolean;
  readonly layoutType: KlarnaLayout;
  readonly snippet: string;
}

export interface IDashboardCounts {
  readonly completed: number;
  readonly left: number;
  readonly scheduled: number;
}

export interface ICorePromoCodeInfo {
  id: number | string;
  title: string;
  description: string | null;
  code: string;
  value: number;
  isAbsolute: boolean;
}

export interface IEditBookingPayload {
  bookingId: number;
  clientId: number;
  date: string;
  serviceId: number;
  locationId: number;
  time: string;
  instructorId: number;
  vehicleGearType: GearType;
  mcLessonType?: MotorcycleLessonType;
}

export interface IBookesSlotQty {
  bookedSlotsQty: number;
}
