import { History } from "history";
import { inject, injectable } from "inversify";
import {
  action,
  computed,
  flow,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
  when,
} from "mobx";
import * as moment from "moment";
import qs from "qs";
import { notEmpty } from "../../../../helpers";
import { IDatetime } from "../../../../helpers/Datetime/interfaces";
import enFlag from "../../../../images/br_flag.png";
import svFlag from "../../../../images/sv_flag.png";

import {
  CoreApiServiceSymbol,
  DatetimeSymbol,
  HistorySymbol,
  I18nServiceSymbol,
  SettingsStoreSymbol,
} from "../../../../inversify/symbols";
import {
  AcademyServiceTypes,
  MotorcycleLessonType,
  ProductType,
  VehicleType,
} from "../../../../models/AcademyServiceModel/interfaces";
import {
  GearType,
  IInstructorModel,
  LanguagesSpoken,
  MultipleGearType,
} from "../../../../models/InstructorModel/interfaces";
import { ILocationModel } from "../../../../models/LocationModel/interfaces";
import { PendingStatus } from "../../../../models/PendingStatus";
import { ICoreApiService } from "../../../../services/CoreApiService/interfaces";
import { II18nService } from "../../../../services/I18nService/interfaces";
import { safeLocalStorage } from "../../../../stores/LocalStorage";
import {
  ISettingsStore,
  ServiceKey,
} from "../../../../stores/SettingsStore/interfaces";
import { isString } from "../../../../stores/UserStore";
import {
  IIntervalWithMeta,
  IIntervalWithWorkload,
} from "../../../bookings/OrderScreen/OrderForm/interfaces";
import { IClassMeta, IDatePickerOption } from "../../../shared/DatePicker";
import {
  ICityOption,
  IInstructorOption,
  IInstructorSelectOption,
  IRegionOption,
  IServiceOption,
} from "../../../shared/forms/booking/interfaces";
import { calcServiceOptionType } from "../../../shared/forms/booking/ServiceListOption";
import { ITimePickerOpt } from "../../../shared/forms/TimePickerExtended";
import { IDemoFormModel, IWidgetFormData } from "./interfaces";
import { lazyInject } from "@/inversify/container";
import { Language } from "../../../../services/I18nService/interfaces";

export const WIDGET_FORM_DATA_KEY = "widget:formData";

export const dict = {
  enTitle: "english",
  enVal: "anyEnglishSpeakingInstructor",
  svTitle: "svenska",
  svVal: "anySwedishSpeakingInstructor",
};

export const ANY_SV_INSTRUCTORS_OPT = {
  picture: svFlag,
  value: dict.svVal,
};
export const ANY_EN_INSTRUCTORS_OPT = {
  picture: enFlag,
  value: dict.enVal,
};

@injectable()
export class DemoFormModel implements IDemoFormModel {
  @computed
  public get dateOptions(): ReadonlyArray<IDatePickerOption<IClassMeta>> {
    return this.getDateOptions();
  }

  @computed
  public get timeOptions(): ITimePickerOpt[] {
    const { selectedDate } = this;
    if (!selectedDate) {
      return [];
    }
    const dateTime = selectedDate.getTime();
    return this.availableInstructorsSlots
      .filter((slot) => {
        const { dates } = slot;
        const dateNow = Date.now();

        if (!slot.isClass) {
          return dates.from.getTime() - dateNow > 30 * 60 * 1000;
        }
        if (slot.isClass) {
          const d = new Date(dates.to);
          const twoHourFromCourseEnd = d.setTime(d.getTime() + 2 * 3600000);
          return twoHourFromCourseEnd > dateNow;
        }
      })
      .filter((slot) => {
        const { dates } = slot;
        return (
          moment(dates.from).clone().startOf("day").toDate().getTime() ===
          dateTime
        );
      })
      .sort((a, b) => a.dates.from.valueOf() - b.dates.from.valueOf())
      .map((slot) => ({
        availableQty: slot.availableQty,
        date: slot.dates.from,
        entityId: slot.entityId,
        endTime: slot.dates.to,
        isClass: slot.isClass,
        seatsInSlot: slot.seatsInSlot,
      }));
  }

  @computed
  public get regionOptions(): IRegionOption[] {
    const serviceRegions = this.settingsStore.services
      .slice()
      .filter((ser) => ser.isVisible)
      .filter((ser) => ser.vehicleType === this.selectedWheeledVehicle)
      ?.map((ser) => ser.regions)
      .flat();
    const serviceRegionsByVechicle = [...new Set(serviceRegions)];
    return this.settingsStore.regions
      .slice()
      .filter((r) => (typeof r.isVisible !== "undefined" ? r.isVisible : true))
      .filter((r) => serviceRegionsByVechicle.includes(r.id))
      .map((location) => {
        return {
          label: location.name[this.i18n.currentLanguage],
          model: location,
          value: location.id,
        };
      })
      .sort((a, b) => {
        try {
          return a.label.localeCompare(b.label, this.i18n.currentLanguage, {
            numeric: true,
          });
        } catch (e) {
          return 0;
        }
      });
  }
  @computed
  public get cityOptions(): ICityOption[] {
    const serviceLocations = this.settingsStore.services
      .slice()
      .filter((ser) => ser.isVisible)
      .filter((ser) => ser.vehicleType === this.selectedWheeledVehicle)
      ?.map((ser) => ser.ownLocations)
      .flat();

    const serviceLocationsByVechicle = [...new Set(serviceLocations)];

    const options: Array<{
      model: ILocationModel;
      label: string;
      value: number;
    }> = this.settingsStore.locations
      .slice()
      .filter((loc) =>
        (this.selectedRegion?.model.locationsIds || []).includes(loc.id)
      )
      .filter((loc) => serviceLocationsByVechicle.includes(loc.id))
      .filter((loc) => loc.isVisible)
      .sort((a, b) => {
        return a.name[this.i18n.currentLanguage]
          .replace(",", "")
          .localeCompare(
            b.name[this.i18n.currentLanguage].replace(",", ""),
            this.i18n.currentLanguage,
            {
              numeric: true,
            }
          );
      })
      .map((location) => {
        return {
          label: location.name[this.i18n.currentLanguage],
          model: location,
          value: location.id,
        };
      });

    return options;
  }
  @computed
  public get serviceOptions(): IServiceOption[] {
    const { selectedCity } = this;
    if (!selectedCity) {
      return [];
    }
    const location = this.settingsStore.locations.find(
      (x) => x.id === selectedCity.value
    );
    if (!location) {
      console.error("Selected location not found in the SettingsStore");
      return [];
    }

    return this.settingsStore.services
      .slice()
      .filter((service) => {
        if (service.vehicleType === VehicleType.Moped) {
          if (!service.ownLocations.includes(location.id)) {
            return false;
          } else return true;
        } else return service.locations.some((loc) => loc === location.id);
      })
      .filter((ser) => {
        if (!this.selectedWheeledVehicle) {
          return false;
        }
        if (!ser.vehicleType && ser.packageLessonsQty > 0) {
          return true;
        }
        if (ser.vehicleType === this.selectedWheeledVehicle) {
          return true;
        }
      })
      .filter((ser) => {
        if (ser.languageCode) {
          if (ser.languageCode === this.selectedServiceLanguage) {
            return true;
          }
        } else {
          return true;
        }
      })
      .sort((a, b) => {
        if (selectedCity.value) {
          const posA = a.positionInLocation.get(selectedCity.model.regionId);
          const posB = b.positionInLocation.get(selectedCity.model.regionId);

          if (typeof posA === "number" && typeof posB === "number") {
            return posA - posB;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      })
      .map((service): IServiceOption => {
        return {
          isHide: service.isVisible != null && !service.isVisible,
          label: service.name[this.i18n.currentLanguage],
          model: service,
          type: calcServiceOptionType(service),
          value: service.id,
          isClass: service.isClass,
          productType: service.productType,
          isNotStudentBookable: service.isNotStudentBookable,
        };
      })
      .sort((a, b) => a.type - b.type);
  }

  @computed
  public get instructorOptions(): IInstructorOption[] {
    const { isAutomaticCar, selectedService, selectedCity } = this;

    if (!selectedService || !selectedCity) {
      return [];
    }

    const { availableUnitsIds, meta } = selectedService.model;

    const { availableInstructorsIds } = selectedCity.model;

    return this.settingsStore.instructors
      .filter((instructor) => {
        return availableInstructorsIds
          ? availableInstructorsIds.find((id) => instructor.id === id)
          : true;
      })
      .filter((instructor) => {
        return availableUnitsIds.find((id) => instructor.id === id);
      })
      .filter((instructor) => instructor.isActive)
      .filter((instructor) => {
        if (selectedService && meta?.type !== AcademyServiceTypes.Lesson) {
          return true; // courses and tests available with any transmission
        }
        if (this.selectedVehicleGearType) {
          return (
            this.selectedVehicleGearType &&
            instructor.instructorVehicleGearTypes?.includes(
              this.selectedVehicleGearType
            )
          );
        }
      })
      .sort((a, b) => {
        const posA = a.positionInLocation.get(selectedCity.value);
        const posB = b.positionInLocation.get(selectedCity.value);

        if (typeof posA === "number" && typeof posB === "number") {
          return posA - posB;
        } else {
          return 0;
        }
      })
      .map((instructor) => {
        return {
          label: instructor.name[this.i18n.currentLanguage],
          model: instructor,
          value: String(instructor.id),
        };
      });
  }

  @computed
  public get additionalInstructorOptions(): IInstructorSelectOption[] {
    const anyEnglishInstrOpt =
      this.englishSpeakingInstructors.length > 0
        ? {
            ...ANY_EN_INSTRUCTORS_OPT,
            label: this.i18n.i18next
              .t("order.chooseAnyEngInstructor")
              .toString(),
          }
        : undefined;

    const anySwedishInstrOpt =
      this.englishSpeakingInstructors.length > 0
        ? {
            ...ANY_SV_INSTRUCTORS_OPT,
            label: this.i18n.i18next.t("order.chooseAnyInstructor").toString(),
          }
        : undefined;

    return [anySwedishInstrOpt, anyEnglishInstrOpt].filter(notEmpty);
  }

  @computed
  public get options(): IInstructorSelectOption[] {
    return !!this.instructorOptions.length
      ? [
          ...this.additionalInstructorOptions,
          ...this.instructorOptions.map((opt) => {
            const { model, ...rest } = opt;
            return {
              ...rest,
              picture: model.picture,
            };
          }),
        ]
      : [];
  }

  @computed
  public get englishSpeakingInstructors(): number[] {
    return this.instructorOptions
      .filter((el) =>
        el.model.languagesSpoken.some((lang) => lang === LanguagesSpoken.EN)
      )
      .map((el) => el.model.id);
  }
  @computed
  public get swedishSpeakingInstructorsIds(): number[] {
    return this.instructorOptions
      .filter((el) =>
        el.model.languagesSpoken.some((lang) => lang === LanguagesSpoken.SV)
      )
      .map((el) => el.model.id);
  }

  @computed
  public get instructorIdsList(): number[] {
    if (this.selectedOption?.value === dict.svVal) {
      return this.swedishSpeakingInstructorsIds;
    } else if (this.selectedOption?.value === dict.enVal) {
      return this.englishSpeakingInstructors;
    } else {
      return [];
    }
  }

  @computed
  public get transmissionMatter(): boolean {
    const { selectedService } = this;
    return (
      !!selectedService &&
      selectedService.model.meta?.type === AcademyServiceTypes.Lesson
    );
  }

  @computed
  public get isBundle(): boolean {
    const { selectedService } = this;
    return (
      !!selectedService &&
      selectedService.model.productType === ProductType.Bundle
    );
  }

  private get queryParams(): IWidgetFormData | undefined {
    const querySearchString = this.history.location.search.replace("?", "");
    const params = qs.parse(querySearchString);

    const {
      locationId,
      date,
      time,
      instructorId,
      promo,
      isAutomaticCar,
      serviceId,
      regionId,
      productType,
      vehicleType,
      mcLessonType,
      vehicleGearType,
      language,
    } = params;

    const thereAreQuery = Object.keys(params).some((key) => !!params[key]);

    return thereAreQuery
      ? {
          promoCode: isString(promo) ? promo : undefined,
          selectedCityId: Number(locationId) || undefined,
          selectedDate: Number(date) || undefined,
          selectedInstructorId: Number(instructorId) || undefined, // fixme
          selectedRegionId: Number(regionId),
          selectedServiceId: Number(serviceId) || undefined, // fixme
          selectedTime: Number(time) || undefined,
          selectedProductType: productType?.toString() || undefined,
          selectedVehicleGearType: vehicleGearType
            ? vehicleGearType?.toString()
            : Boolean(Number(isAutomaticCar))
            ? GearType.Automatic
            : GearType.Manual,
          selectedMotorcycleLessonType: mcLessonType?.toString() || undefined,
          selectedWheeledVehicle: vehicleType
            ? vehicleType.toString()
            : VehicleType.car,
        }
      : undefined;
  }

  private static storeWidgetFormData(widgetData: IWidgetFormData) {
    safeLocalStorage.setItem(WIDGET_FORM_DATA_KEY, JSON.stringify(widgetData));
  }
  public datePickerLoading = new PendingStatus();

  @observable
  public selectedWheeledVehicle: VehicleType | undefined;
  @observable
  public selectedRegion: IRegionOption | undefined;
  @observable
  public selectedCity: ICityOption | undefined;
  @observable
  public promoCode: string | undefined;
  @observable
  public selectedService: IServiceOption | undefined;
  @observable
  public selectedServiceLanguage: Language | undefined =
    this.i18nService.currentLanguage;
  @observable
  public selectedVehicleGearType: GearType | undefined;
  @observable
  public selectedMotorcycleLessonType: MotorcycleLessonType | undefined;
  @observable
  public selectedInstructor: IInstructorOption | undefined;
  @observable
  public selectedOption: IInstructorSelectOption | undefined;
  @observable
  public selectedDate: Date | undefined;

  @observable
  public datePickerDate: Date = new Date();
  @observable
  public selectedTime: Date | undefined;
  @observable
  public isAutomaticCar: boolean | null = null;
  private availableInstructorsSlots = observable.array<IIntervalWithMeta>();
  private fieldsWatcherDisposer: IReactionDisposer | undefined;
  private readonly selectableDatesDisposer: IReactionDisposer | undefined;

  private restoreForm = flow(function* (this: DemoFormModel) {
    const data: IWidgetFormData | undefined = this.queryParams;

    if (!!data) {
      yield this.restoreFormFromQP(data);

      DemoFormModel.storeWidgetFormData(data);
    }
  });

  private restoreFormFromQP = flow(function* (
    this: DemoFormModel,
    data: IWidgetFormData
  ) {
    try {
      const {
        selectedCityId,
        selectedServiceId,
        selectedInstructorId,
        selectedDate,
        selectedTime,
        isAutomaticCar,
        promoCode,
        selectedRegionId,
        selectedProductType,
        selectedVehicleGearType,
        selectedWheeledVehicle,
        selectedMotorcycleLessonType,
      } = data;

      if (promoCode) {
        this.setPromo(promoCode);
      }

      if (selectedWheeledVehicle) {
        this.selectWheeledVehicle(selectedWheeledVehicle as VehicleType);
      } else {
        this.selectWheeledVehicle(VehicleType.car);
      }

      let regionOption: IRegionOption | undefined;
      regionOption = selectedRegionId
        ? this.regionOptions.find((option) => option.value === selectedRegionId)
        : undefined;

      if (regionOption) {
        this.selectRegion(regionOption);
      } else {
        console.warn("Region is not found or not available anymore");
        return;
      }

      let cityOption: ICityOption | undefined;
      cityOption = selectedCityId
        ? this.cityOptions.find((option) => option.value === selectedCityId)
        : undefined;

      if (cityOption) {
        this.selectCity(cityOption);
      } else {
        console.warn("City is not found or not available anymore");
        return;
      }

      const serviceOption = selectedServiceId
        ? this.serviceOptions
            .filter((option) => option.productType === selectedProductType)
            .find((option) => option.value === selectedServiceId)
        : undefined;

      if (serviceOption) {
        this.selectService(serviceOption);
      } else {
        return;
      }

      let gearType;
      if (selectedVehicleGearType) {
        this.selectVehicleGearType(selectedVehicleGearType as GearType);
      }

      if (selectedMotorcycleLessonType) {
        this.selectMotorcycleLessonType(
          selectedMotorcycleLessonType as MotorcycleLessonType
        );
      }

      if (selectedInstructorId) {
        this.settingsStore.instructors.map((instructor) => {
          if (instructor.id === selectedInstructorId) {
            //isAutomatic = instructor.isAutomaticCar;
          }
        });
      } else {
        //isAutomatic = isAutomaticCar;
      }

      const instructorOption = selectedInstructorId
        ? this.instructorOptions.find(
            (option) => Number(option.value) === selectedInstructorId
          )
        : undefined;

      if (instructorOption) {
        runInAction(() => {
          this.datePickerDate = new Date(selectedDate || Date.now());
          this.selectOption({
            ...instructorOption,
            picture: instructorOption.model.picture,
          });
        });
      } else {
        return;
      }

      yield when(() => !this.datePickerLoading.isPending);
      if (selectedDate) {
        const startOfDay = moment(new Date(selectedDate))
          .startOf("day")
          .toDate()
          .getTime();

        const dateOptions = this.getDateOptions(startOfDay);

        if (
          dateOptions.some((option) => option.value.getTime() === startOfDay)
        ) {
          this.selectDate(new Date(selectedDate));
          if (selectedTime) {
            if (
              this.timeOptions.some(
                (option) => option.date.getTime() === selectedTime
              )
            ) {
              this.selectTime(new Date(selectedTime));
            } else {
              console.warn("Selected time is not longer available");
            }
          }
        } else {
          console.warn("Selected date is not longer available");
        }
      }
    } catch {
      console.warn("something went wrong");
    }
  });
  constructor(
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService,
    @inject(HistorySymbol) private readonly history: History,
    @inject(DatetimeSymbol) private readonly datetime: IDatetime,
    @inject(I18nServiceSymbol) private readonly i18n: II18nService,
    @inject(I18nServiceSymbol)
    private readonly i18nService: II18nService
  ) {
    this.selectableDatesDisposer = reaction(
      () => ({
        datePickerDate: this.datePickerDate,
        selectedInstructor: this.selectedOption,
        selectedLocation: this.selectedCity,
        selectedService: this.selectedService,
      }),
      ({
        datePickerDate,
        selectedInstructor,
        selectedService,
        selectedLocation,
      }) => {
        this.updateSelectableDates(
          datePickerDate,
          !!this.instructorIdsList.length
            ? this.instructorIdsList
            : selectedInstructor && [Number(selectedInstructor.value)],
          selectedService,
          selectedLocation
        ).catch((err) => console.error(err));
      }
    );
  }

  public async mount() {
    await this.restoreForm();
    this.fieldsWatcherDisposer = reaction(
      () => ({
        selectedWheeledVehicle: this.selectedWheeledVehicle,
        selectedVehicleGearType: this.selectedVehicleGearType,
        promoCode: this.promoCode,
        selectedCity: this.selectedCity,
        selectedDate: this.selectedDate,
        selectedInstructor: this.selectedOption,
        selectedService: this.selectedService,
        selectedMotorcycleLessonType: this.selectedMotorcycleLessonType,
        selectedProductType: this.selectedService?.productType,
        selectedTime: this.selectedTime,
      }),
      ({
        selectedWheeledVehicle,
        selectedVehicleGearType,
        selectedCity,
        selectedService,
        selectedProductType,
        selectedInstructor,
        selectedDate,
        selectedTime,
        promoCode,
        selectedMotorcycleLessonType,
      }) => {
        DemoFormModel.storeWidgetFormData({
          selectedWheeledVehicle: selectedWheeledVehicle
            ? selectedWheeledVehicle
            : VehicleType.car,
          selectedVehicleGearType: selectedVehicleGearType,
          promoCode: promoCode || undefined,
          selectedCityId: selectedCity ? selectedCity.value : undefined,
          selectedDate: selectedDate && selectedDate.getTime(),
          selectedInstructorId: selectedInstructor
            ? Number(selectedInstructor.value)
            : undefined,
          selectedRegionId: this.selectedRegion?.value,
          selectedServiceId: selectedService
            ? selectedService.value
            : undefined,
          selectedProductType: selectedProductType,
          selectedTime: selectedTime && selectedTime.getTime(),
          selectedMotorcycleLessonType: selectedMotorcycleLessonType,
        });
      }
    );
  }
  public unmount() {
    if (this.fieldsWatcherDisposer) {
      this.fieldsWatcherDisposer();
    }
    if (this.selectableDatesDisposer) {
      this.selectableDatesDisposer();
    }
  }

  @action
  public selectWheeledVehicle(vehicle: VehicleType | undefined) {
    this.selectedWheeledVehicle = vehicle;
  }

  @action
  public selectRegion(region: IRegionOption | undefined) {
    this.selectedRegion = region;
  }
  @action
  public selectCity(city: ICityOption | undefined) {
    this.selectedCity = city;
  }
  @action
  public setPromo(val: string) {
    this.promoCode = val;
  }

  @action
  public selectService(service: IServiceOption | undefined) {
    this.selectedService = service;
    if (!this.transmissionMatter) {
      this.setIsAutomaticCar(null);
    }
  }
  @action
  public selectServiceLanguage(lng: Language | undefined) {
    this.selectedServiceLanguage = lng || this.i18nService.currentLanguage;
  }
  @action
  public selectVehicleGearType(gearType: GearType | undefined) {
    this.selectedVehicleGearType = gearType;
  }
  @action
  public selectMotorcycleLessonType(val: MotorcycleLessonType | undefined) {
    this.selectedMotorcycleLessonType = val;
  }
  @action
  public selectDate(date: Date | undefined) {
    this.selectedDate = date;
  }
  @action
  public changeDatePickerDate(date: Date) {
    this.datePickerDate = date;
  }

  @action
  public selectTime(time: Date | undefined) {
    this.selectedTime = time;
  }

  @action
  public selectInstructor(instructor: IInstructorOption | undefined) {
    this.selectedInstructor = instructor;
  }

  @action
  public selectOption(instructor: IInstructorSelectOption | undefined) {
    if (!!instructor) {
      const instructorOption = this.instructorOptions.find(
        (x) => x.model.id === Number(instructor.value)
      );
      this.selectInstructor(instructorOption);
    }
    this.selectedOption = instructor;
  }

  public getInstructorOptionById(id: number): IInstructorOption | undefined {
    const instructor = this.settingsStore.instructors.find(
      (currentInstructor) => id === currentInstructor.id
    );

    return !!instructor ? this.instructorToOption(instructor) : undefined;
  }

  @action
  public setIsAutomaticCar(isAutomatic: boolean | null) {
    this.isAutomaticCar = isAutomatic;
    const { selectedInstructor } = this;
    if (
      this.transmissionMatter &&
      selectedInstructor &&
      !this.instructorOptions.find(
        (option) => option.value === selectedInstructor.value
      )
    ) {
      this.selectInstructor(undefined);
      this.selectOption(undefined); // instructor is not available
    }
  }

  @action
  private replaceAvailableInstructorSlots(val: IIntervalWithMeta[]): void {
    this.availableInstructorsSlots.replace(val);
    const { selectedDate, selectedTime } = this;
    let selectedDateAvailable = false;
    if (selectedDate) {
      selectedDateAvailable = val.some((interval) => {
        return (
          moment(interval.dates.from).startOf("day").valueOf() ===
          selectedDate.getTime()
        );
      });
    }
    if (!selectedDateAvailable) {
      this.selectDate(undefined);
    }
    let selectedTimeAvailable = false;
    if (selectedTime) {
      selectedTimeAvailable = val.some((interval) => {
        return moment(interval.dates.from).valueOf() === selectedTime.getTime();
      });
    }
    if (!selectedTimeAvailable) {
      this.selectTime(undefined);
    }
  }

  private getDateOptions(startingDate = Date.now()): Array<{
    model: { disabled: boolean };
    value: Date;
  }> {
    const dateSlots: {
      [dateStartTs: number]: {
        availableQty: number;
      };
    } = {};

    this.availableInstructorsSlots
      .filter((slot) => {
        const { dates } = slot;
        if (!slot.isClass) {
          const d = new Date(dates.from);
          const halfHourFromNow = d.setTime(d.getTime() + -30 * 60000);
          return halfHourFromNow > startingDate;
        }
        if (slot.isClass) {
          const d = new Date(dates.to);
          const twoHourFromCourseEnd = d.setTime(d.getTime() + 2 * 3600000);
          return twoHourFromCourseEnd > startingDate;
        }
      })
      .forEach((slot) => {
        const dayStartTs = moment(slot.dates.from)
          .clone()
          .startOf("day")
          .valueOf();

        if (dateSlots[dayStartTs]) {
          dateSlots[dayStartTs].availableQty += slot.availableQty;
        } else {
          dateSlots[dayStartTs] = {
            availableQty: slot.availableQty,
          };
        }
      });

    return Object.keys(dateSlots).map((tsStr) => ({
      model: { disabled: !dateSlots[parseInt(tsStr)].availableQty },
      value: new Date(parseInt(tsStr)),
    }));
  }

  private instructorToOption(instructor: IInstructorModel): IInstructorOption {
    return {
      label: instructor.name[this.i18n.currentLanguage],
      model: instructor,
      value: String(instructor.id),
    };
  }

  private async updateSelectableDates(
    datePickerDate: Date,
    selectedInstructorsIds?: number[],
    selectedService?: IServiceOption,
    selectedLocation?: ICityOption
  ) {
    try {
      this.datePickerLoading.startPending();
      const isFulfilled = <T>(
        p: PromiseSettledResult<T>
      ): p is PromiseFulfilledResult<T> => p.status === "fulfilled";
      if (selectedService && selectedLocation) {
        const from = moment(datePickerDate)
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        const to = moment(datePickerDate)
          .clone()
          .endOf("month")
          .format("YYYY-MM-DD");

        const isClass = !!selectedService.value && selectedService.isClass;
        const isBundleMustBeBookedOnPurchase =
          !!selectedService.value &&
          selectedService.model.mustBeBookedOnPurchase;
        const isBundle =
          !!selectedService.value &&
          selectedService.model.productType === ProductType.Bundle;

        if (isClass || (isBundle && isBundleMustBeBookedOnPurchase)) {
          const slots = await this.coreApiService.getAvailableClassesSlots(
            from,
            to,
            isBundle
              ? selectedService.model.serviceToBook?.serviceId || 0
              : selectedService.value
          );

          const mappedSlots = slots.map((slot) => {
            return {
              availableQty: slot.qty,
              dates: { ...slot.interval },
              entityId: selectedService.value,
              isClass,
              seatsInSlot: slot.seats,
            };
          });

          this.replaceAvailableInstructorSlots(mappedSlots);
        } else {
          if (!!selectedInstructorsIds?.length) {
            const hasMultipleGearType =
              this.selectedVehicleGearType &&
              [GearType.McA2, GearType.McA].includes(
                this.selectedVehicleGearType
              ) &&
              selectedService.model.meta?.key !== ServiceKey.DrivingTest;
            const result = await Promise.allSettled(
              selectedInstructorsIds.map(async (instructorId) => {
                try {
                  const slots =
                    this.selectedWheeledVehicle === VehicleType.Mc
                      ? await this.coreApiService.getAvailableSlotsForMcNewbie(
                          from,
                          to,
                          instructorId,
                          selectedService.value,
                          hasMultipleGearType
                            ? MultipleGearType.McAMcA2
                            : this.selectedVehicleGearType,
                          selectedLocation.value
                        )
                      : await this.coreApiService.getAvailableSlots(
                          from,
                          to,
                          instructorId,
                          selectedService.value,
                          isClass,
                          hasMultipleGearType
                            ? MultipleGearType.McAMcA2
                            : this.selectedVehicleGearType,
                          selectedLocation.value
                        );
                  const workload =
                    await this.coreApiService.getInstructorWorkload(
                      from,
                      to,
                      instructorId
                    );

                  return slots.map((slot) => ({
                    availableQty: slot.qty,
                    dates: { ...slot.interval },
                    entityId: instructorId,
                    isClass: false,
                    seatsInSlot: slot.seats,
                    workload,
                  }));
                } catch (e) {
                  console.error(e);
                  return [];
                }
              })
            );

            const dataDict: {
              [key: string]: IIntervalWithWorkload;
            } = {};
            const fulfilledValues = result
              .filter(isFulfilled)
              .map((p) => p.value);

            fulfilledValues
              .reduce((acc, val) => acc.concat(val), [])
              .forEach((el) => {
                const { dates } = el;
                const key = dates.from.getTime();

                if (dataDict.hasOwnProperty(key)) {
                  if (dataDict[key].workload >= el.workload) {
                    dataDict[key] = el;
                  }
                } else {
                  dataDict[key] = el;
                }
              });

            const availableTimeSlots: IIntervalWithMeta[] = Object.values(
              dataDict
            ).map((el) => {
              const { workload, ...res } = el;
              return res;
            });

            this.replaceAvailableInstructorSlots(availableTimeSlots);
          } else {
            this.replaceAvailableInstructorSlots([]);
          }
        }
      } else {
        this.replaceAvailableInstructorSlots([]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.datePickerLoading.stopPending();
    }
  }
}
