import { ICalendarEvent } from "../../components/bookings/ScheduleScreen/Calendar/interfaces";
import { IBooking } from "../../services/CoreApiService/interfaces";
import { MotorcycleLessonType } from "../AcademyServiceModel/interfaces";
import { GearType, IInstructorModel } from "../InstructorModel/interfaces";
import { IDrivingExam, ITheoryExam } from "../UserModel/interfaces";

export interface IPickup {
  htmlFormatted: boolean; // if true - show it as it is, without any additional labels
  address: string;
  coords?: {
    lat: number;
    lng: number;
  };
}
export interface IVehicle {
  id: number;
  gearType: GearType;
  plateInfo: string;
}
export interface ILessonEventModel extends ICalendarEvent<ILessonEventModel> {
  readonly instructor: IInstructorModel | undefined;
  readonly fallbackInstructorName: string | undefined;
  readonly isUTB: boolean;
  readonly isDriving: boolean;
  readonly pickup: IPickup;
  readonly vehicle?: IVehicle;
  readonly mcLessonType?: MotorcycleLessonType;
  readonly externalComment?: string;
  isPaid: boolean;
  readonly isNotStudentBookable: boolean;

  setVehicle(vehicle?: IVehicle): void;
  setInstructor(instructor: IInstructorModel | undefined): void;
  setFallbackInstructor(instructorName: string | undefined): void;
  setPickup(pickup: IPickup): void;
  setIsUTB(isUTB: boolean): void;
  setIsDriving(isDriving: boolean): void;
  setIsLocked(isLocked: boolean): void;
  setMcLessonType(type: MotorcycleLessonType | undefined): void;
  setExternalComment(comment: string | undefined): void;
  setIsNotStudentBookable(isNotStudentBookable: boolean): void;
}

export function isLessonEventModel(
  event: ICalendarEvent<any>
): event is ILessonEventModel {
  return "isDriving" in event;
}

export function isClassEventModel(
  event: ICalendarEvent<any>
): event is IClassEventModel {
  return "isDriving" in event;
}

export interface IClassEventModel extends ICalendarEvent<IClassEventModel> {
  readonly pickup: IPickup;
  readonly isDriving?: boolean;

  setPickup(pickup: IPickup): void;
  setIsDriving(isDriving: boolean): void;
}

export interface IFbTheoryExamEventModel
  extends ICalendarEvent<IFbTheoryExamEventModel> {
  readonly isTheoryExam: true;
  readonly location: string;
  setLocation(location: string): void;
}

export function isFbTheoryExamEventModel(
  event: ICalendarEvent<any>
): event is IFbTheoryExamEventModel {
  return (
    "isTheoryExam" in event && (event as IFbTheoryExamEventModel).isTheoryExam
  );
}

export interface IFbDrivingExamEventModel
  extends ICalendarEvent<IFbDrivingExamEventModel> {
  readonly isDrivingExam: true;
  readonly location: string;
  setLocation(location: string): void;
}

export function isFbDrivingExamEventModel(
  event: ICalendarEvent<any>
): event is IFbDrivingExamEventModel {
  return (
    "isDrivingExam" in event &&
    (event as IFbDrivingExamEventModel).isDrivingExam
  );
}

export interface IEventModelFactory {
  eventFromApi(apiBooking: IBooking): IClassEventModel | ILessonEventModel;
  createFbTheoryExamEvent(data: ITheoryExam): IFbTheoryExamEventModel;
  createFbDrivingExamEvent(data: IDrivingExam): IFbDrivingExamEventModel;
}
