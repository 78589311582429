import { injectable } from "inversify";
import { mapNameToMultiLanguageName } from "../../helpers";
import {
  IApiRegion,
  ORDER_OF_PACKAGES,
  UnsafeFirebaseDictionary,
} from "../../stores/SettingsStore/interfaces";
import { IApiBundle, IContent } from "../BundleModel/interfaces";
import { ICoreServiceSortable } from "./interfaces";
import {
  AcademyServiceTypes,
  IAcademyServiceModel,
  ICoreService,
  isService,
  ProductType,
} from "./interfaces";

export interface IServiceData {
  data: IAcademyServiceModel;
}

@injectable()
export class AcademyServiceModel implements IServiceData {
  public readonly data: IAcademyServiceModel;
  private readonly assetsBase =
    "https://mydrivingacademy.secure.enterpriseappointments.com";

  constructor(
    private readonly service: ICoreServiceSortable,
    services: ICoreService[],
    regions?: IApiRegion[]
  ) {
    this.data = isService(service)
      ? this.mapFromApiService(service)
      : this.mapFromApiBundle(service, services, regions);
  }
  private mapFromApiBundle(
    service: IApiBundle,
    services: ICoreService[],
    regions?: IApiRegion[]
  ): IAcademyServiceModel {
    const {
      id,
      name,
      description,
      content,
      price,
      priceWithVat,
      regionId,
      inRegionsSortingIndexes,
      vehicleType,
      languageCode,
      mustBeBookedOnPurchase,
      serviceToBook,
    } = service;
    const locations =
      (regions || [])
        .find((region) => region.id === regionId)
        ?.locations.map((loc) => loc.id) || [];
    const bundleServiceForOwnLocation = () => {
      const hasAutoBookIndex = content.some(
        (ser) => ser.productProcessingConfig?.autoBookingData?.autoBookIndex
      );

      if (!hasAutoBookIndex) return content;
      const autoBookIndexFilter = (index) =>
        content.filter(
          (ser) =>
            ser.productProcessingConfig?.autoBookingData.autoBookIndex === index
        );

      if (autoBookIndexFilter(0).length > 0) {
        return autoBookIndexFilter(0);
      } else if (autoBookIndexFilter(1).length > 0) {
        return autoBookIndexFilter(1);
      } else {
        return content;
      }
    };

    const contentIds = bundleServiceForOwnLocation()?.map((ser) => ser.id);

    const ownLocations = Array.from(
      new Set(
        ...services
          .filter((ser) => contentIds.includes(ser.id))
          .map((ser) => ser.associationsIds.ownLocations)
      )
    );

    const positionInLocationDict = this.dictFromUnsafeValue(
      inRegionsSortingIndexes
    );
    const positionInLocation = new Map<number, number>();
    for (const locationKey of Object.keys(positionInLocationDict)) {
      positionInLocation.set(
        Number(locationKey),
        positionInLocationDict[locationKey]
      );
    }

    return {
      id,
      name: mapNameToMultiLanguageName(`${name.en} ·`, `${name.sw} ·`),
      description: mapNameToMultiLanguageName(description.en, description.sw),
      isActive: true,
      isVisible: true,
      productType: ProductType.Bundle,
      locations,
      ownLocations: ownLocations,
      regions: regionId ? [regionId] : [],
      packageLessonsQty: content.reduce((acc, item) => acc + item.qty, 0),
      position: 1,
      price: price / 100,
      priceWithVat: priceWithVat / 100,
      isClass: false,
      availableUnitsIds: [],
      onTop: (service as any).onTop,
      sortIndex: (service as any).sortIndex,
      positionInLocation,
      vehicleType,
      languageCode,
      mustBeBookedOnPurchase,
      serviceToBook,
    };
  }

  private mapFromApiService(service: ICoreService): IAcademyServiceModel {
    const {
      id,
      name,
      description,
      image,
      type,
      price,
      priceWithVat,
      associationsIds,
      key,
      packageLessonsQty,
      inRegionsSortingIndexes,
      duration,
      vehicleType,
      mustBeBookedOnPurchase,
      serviceToBook,
      options,
      ...rest
    } = service;
    const isClass = type === AcademyServiceTypes.Class;

    const positionInLocationDict = this.dictFromUnsafeValue(
      inRegionsSortingIndexes
    );

    const positionInLocation = new Map<number, number>();
    for (const locationKey of Object.keys(positionInLocationDict)) {
      positionInLocation.set(
        Number(locationKey),
        positionInLocationDict[locationKey]
      );
    }
    const isNotStudentBookable = options?.notStudentBookable;

    return {
      ...rest,
      id,
      name: mapNameToMultiLanguageName(`${name.en} ·`, `${name.sw} ·`),
      description: mapNameToMultiLanguageName(description.en, description.sw),
      productType: ProductType.Service,
      locations: associationsIds.locations,
      exchangeables: associationsIds.exchangeableLocationsToServicesMapping,
      ownLocations: associationsIds.ownLocations,
      regions: associationsIds.regions,
      packageLessonsQty: packageLessonsQty || 1,
      position: ORDER_OF_PACKAGES.findIndex((pack) => pack === key),
      positionInLocation,
      price: price / 100,
      priceWithVat: priceWithVat / 100,
      isClass,
      availableUnitsIds: associationsIds.instructors,
      vehicleType,
      meta: {
        type,
        key,
        duration,
      },
      onTop: (service as any).onTop,
      sortIndex: (service as any).sortIndex,
      mustBeBookedOnPurchase,
      serviceToBook,
      isNotStudentBookable,
    };
  }

  private dictFromUnsafeValue<T>(unsafeValue: UnsafeFirebaseDictionary<T>): {
    [key: string]: T;
  } {
    let res: { [key: number]: T };
    if (!unsafeValue) {
      res = {};
    } else if (Array.isArray(unsafeValue)) {
      res = {};
      unsafeValue.forEach((v, i) => {
        res[i] = v;
      });
    } else {
      res = unsafeValue;
    }

    return res;
  }
}
