import { observer } from "mobx-react";
import * as React from "react";
import { lazyInject } from "../../../../../inversify/container";
import { I18nServiceSymbol } from "../../../../../inversify/symbols";
import { II18nService } from "../../../../../services/I18nService/interfaces";
import { Color } from "../../../../../styles/interfaces";
import { ICalendarModel } from "../interfaces";
import { ILegendItemProps, LegendItem } from "./LegendItem";
import styles from "./styles.scss";

interface ICalendarLegendProps {
  model: ICalendarModel;
}

@observer
export class CalendarLegend extends React.Component<ICalendarLegendProps, {}> {
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;
  public render() {
    return (
      <div className={styles.listRow}>
        <LegendItemStyled backgroundColor={Color.Mushroom}>
          {this.i18nService.i18next.t("scheduleScreen.introCourse").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.Wheat}>
          {this.i18nService.i18next.t("scheduleScreen.testLesson").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.FadedOrange}>
          {this.i18nService.i18next.t("scheduleScreen.lesson")}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.RosePink}>
          {this.i18nService.i18next.t("scheduleScreen.risk1").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.Rose}>
          {this.i18nService.i18next.t("scheduleScreen.risk2").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.Wisteria}>
          {this.i18nService.i18next
            .t("scheduleScreen.educationControl")
            .toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.SickGreen}>
          {this.i18nService.i18next.t("scheduleScreen.theoryTest").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.Sea}>
          {this.i18nService.i18next.t("scheduleScreen.drivingTest").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.LightGreyBlue}>
          {this.i18nService.i18next.t("scheduleScreen.moped").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.PastelBlue}>
          {this.i18nService.i18next.t("scheduleScreen.theoryGroup").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.BrightOrange}>
          {this.i18nService.i18next.t("scheduleScreen.taxi").toString()}
        </LegendItemStyled>
        <LegendItemStyled
          backgroundColor={Color.White}
          special={Color.Attention}
        >
          {this.i18nService.i18next.t("scheduleScreen.reservePay").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.Magenta}>
          {this.i18nService.i18next.t("scheduleScreen.mcLesson").toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.DarkViolet}>
          {this.i18nService.i18next
            .t("scheduleScreen.mcDrivingTest")
            .toString()}
        </LegendItemStyled>
        <LegendItemStyled backgroundColor={Color.DarkPink}>
          {this.i18nService.i18next
            .t("scheduleScreen.mcIntensiveCourse")
            .toString()}
        </LegendItemStyled>
      </div>
    );
  }
}

function LegendItemStyled(props: ILegendItemProps) {
  return (
    <div className={styles.legendItemContainer}>
      <LegendItem {...props} />
    </div>
  );
}
